@import url('https://fonts.googleapis.com/css2?family=Mukta&family=Noto+Sans:wght@200;300;500&family=Nunito+Sans:wght@400;600&display=swap');

@font-face {
    font-family: 'Cerebri Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/CerebriSansPro/CerebriSansPro-Regular.otf') format('opentype'),
        url('../fonts/CerebriSansPro/CerebriSansPro-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Cerebri Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/CerebriSansPro/CerebriSansPro-SemiBold.otf') format('opentype'),
        url('../fonts/CerebriSansPro/CerebriSansPro-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Cerebri Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/CerebriSansPro/CerebriSansPro-Bold.otf') format('opentype'),
        url('../fonts/CerebriSansPro/CerebriSansPro-Bold.ttf') format('truetype');
}

body {
    font-family: 'Cerebri Sans Pro', sans-serif;
}

.text-xs {
    font-size: 0.65rem;
}

.text-smd {
    font-size: 0.8rem;
}

.navbar-brand {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 200;
    font-size: 1.25em;
    a {
        color: var(--white) !important;
    }
}

.page-sidenav, .sidenav {
    background: rgb(40, 49, 73);
    color: white !important;
    a {
        color: white !important;
    }
}

.page-sidenav:after, .sidenav:after {
    background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
}

.sidenav {
    .nav {
        font-family: 'Mukta', sans-serif;
        .nav-item a.nav-link.active, .nav-item a.nav-link:active {
            background-color: rgba(86, 145, 216, 0.57);
        }
        .nav-link:hover {
            color: rgba(86, 145, 216, 0.57);
        }
        .nav-link:focus {
            color: white;
        }
    }
}

@media (max-width: 991px) {
    header {
        padding-left: 3.5em;
    }
}


.dropdown-toggle.no-caret::after {
    display: none !important; 
  }

.Kalend__main *, .Kalend__text {
    font-family: 'Noto Sans', sans-serif !important;
}

.Kalend__button {
    display: inline-block;
    font-weight: 400 !important;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent !important;
    padding: 0.375rem 0.75rem !important;
    font-size: .875rem !important;
    line-height: 1.4285714286;
    border-radius: 0.25rem !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

.Kalend__button.Kalend__Event-header, .Kalend__button.Kalend__Event-month {
    padding: 0 !important;
}

.Kalend__button.Kalend__ButtonBase-border {
    color: #647787 !important;
    background-color: #fff !important;
    border-color: rgba(135,150,165,.1) !important;
}

.Kalend__button.Kalend__ButtonBase-border:hover {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
}

.Kalend__header_calendar_buttons__container {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 0.25rem;
    .Kalend__header_calendar_button {
        color: #647787 !important;
        background-color: #fff !important;
        border-color: rgba(135,150,165,.1) !important;
        .Kalend__header_calendar_button-text {
            color: #647787 !important;
        }
    }
    .Kalend__header_calendar_button-selected {
        color: #fff !important;
        background-color: #137eff !important;
        border-color: #137eff !important;
        .Kalend__header_calendar_button-text-selected {
            color: #fff !important;
        }
    }
}

.Kalend__header_calendar_buttons__container>.Kalend__button:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.Kalend__header_calendar_buttons__container>.Kalend__button:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.Kalend__Event-normal {
    padding: 0 !important;
    border-top: 2px solid rgba(0,0,0, 0.25) !important;
    border-radius: 3px !important;
}

